import React, { useState } from "react";
import axios from "axios";

function FileUploader() {
  const [file, setFile] = useState(null);
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState(""); // State for password
  const [error, setError] = useState(""); // State for error message

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!file) return;

    const formData = new FormData();
    formData.append("file", file);

    setLoading(true);
    setError(""); // Clear error message

    try {
      const username = "olset-admin";
      const password = "0cWcE7iq7k7OZowsuMD4mlOm0KsH4ZJ0tRqU4mSwji4";
      const auth = btoa(`${username}:${password}`);

      const res = await axios.post(
        "https://f50f242a-c2f6-4ef4-b512-daae185c0505.pub.instances.scw.cloud/lease-processing",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Basic ${auth}`,
          },
          withCredentials: true,
        }
      );

      console.log("Réponse du serveur:", res.data);
      setResponse(res.data);
    } catch (error) {
      console.error("Erreur lors du téléchargement du fichier:", error);
      setError("Erreur lors du téléchargement du fichier");
    } finally {
      setLoading(false);
    }
  };

  const handleReset = () => {
    setFile(null);
    setPassword("");
    setResponse(null);
    setError("");
    document.getElementById("fileInput").value = null;
  };

  return (
    <div className="container mx-auto max-width px-4 mb-20">
      <h1 className="text-2xl text-dark-heading dark:text-light-heading md:text-4xl xl:text-5xl xl:leading-tight font-bold pb-16">
        Lease processing
      </h1>
      <form onSubmit={handleSubmit} className="space-y-4">
        <input
          type="file"
          id="fileInput"
          onChange={handleFileChange}
          className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
        />
        {file && (
          <p className="text-sm text-gray-600">Selected file: {file.name}</p>
        )}
        <input
          type="password"
          placeholder="Enter password"
          value={password}
          onChange={handlePasswordChange}
          className="block px-4 py-2 mt-2 text-sm text-gray-600 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
        />
        {error && <p className="text-sm text-red-600">{error}</p>}
        <div className="space-x-4">
          <button
            type="submit"
            disabled={!file || loading}
            className={`px-6 py-2 font-semibold rounded-md text-white ${
              loading ? "bg-gray-400" : "bg-blue-600 hover:bg-blue-700"
            }`}
          >
            {loading ? "Loading..." : "Upload File"}
          </button>
          <button
            type="button"
            onClick={handleReset}
            className="px-6 py-2 font-semibold rounded-md text-white bg-red-600 hover:bg-red-700"
          >
            Reset
          </button>
        </div>
      </form>
      {response && (
        <div className="mt-10 w-full">
          <h2 className="text-xl font-bold text-white">Response</h2>
          <pre className="bg-gray-100 p-4 rounded w-full overflow-x-auto">
            {JSON.stringify(response, null, 2)}
          </pre>
        </div>
      )}
    </div>
  );
}

export default FileUploader;
