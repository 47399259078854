// Enter all your detials in this file
// Logo images
import logogradient from "./assets/logo.svg";
import logo from "./assets/logo2.svg";
// Profile Image
import profile from "./assets/profile.png";
// Tech stack images
import react from "./assets/techstack/react.png";
import tailwind from "./assets/techstack/tailwind.png";
import vscode from "./assets/techstack/vscode.png";
import github from "./assets/techstack/github.png";
import git from "./assets/techstack/git.png";
import npm from "./assets/techstack/npm.png";
import figma from "./assets/techstack/figma.png";
import django from "./assets/techstack/django.png";
import flask from "./assets/techstack/flask.png";
import cpp from "./assets/techstack/cpp.png";
import mysql from "./assets/techstack/mysql.png";
// Porject Images
import projectImage1 from "./assets/projects/project1.png";
import projectImage2 from "./assets/projects/project2.png";
import projectImage3 from "./assets/projects/project3.png";

// Logos
export const logos = {
  logogradient: logogradient,
  logo: logo,
};

// Enter your Personal Details here
export const personalDetails = {
  name: "Martin",
  tagline: "Tech entrepreneur and python developer",
  img: profile,
  about: `As a tech entrepreneur and passionate developer, I create innovative SaaS solutions that transform bold ideas into concrete software applications. With expertise in Python development and artificial intelligence, I specialize in harnessing the power of AI to drive automation and efficiency. With a strong background in physics from the University of Lyon 1 and coding skills from École 42, I'm adaptable and dedicated to delivering cutting-edge solutions. Let's connect to explore how AI and tech can revolutionize your business!`,
};

// Enter your Social Media URLs here
export const socialMediaUrl = {
  linkedin: "https://fr.linkedin.com/in/martin-philip-783829175",
  github: "https://github.com/MartinPHLP",
};

// Enter your Work Experience here
export const workDetails = [
  {
    Position: "Frontend Web Developer",
    Company: `Company Name here`,
    Location: "Bengaluru",
    Type: "Full Time",
    Duration: "Sep 2021 - Dec 2021",
  },
  {
    Position: "Internship",
    Company: `Company Name here`,
    Location: "Bengaluru",
    Type: "Internship",
    Duration: "Sep 2021 - Dec 2021",
  },
  {
    Position: "Internship",
    Company: `Company Name here`,
    Location: "Bengaluru",
    Type: "Internship",
    Duration: "Sep 2021 - Dec 2021",
  },
];

// Enter your Education Details here
export const eduDetails = [
  {
    Position: "Frontend Development",
    Company: "Udemy, YouTube, Google, Medium",
    Location: "Online",
    Type: "Full Time",
    Duration: "Jan 2022 - Present",
  },
  {
    Position: "Bachelor in Electronics & Communication",
    Company: `Your College Name here`,
    Location: "Bengaluru",
    Type: "Full Time",
    Duration: "Aug 2020 - Present",
  },
];

// Tech Stack and Tools
export const techStackDetails = {
    django: django,
    flask: flask,
    cpp: cpp,
    mysql: mysql,
    react: react,
    tailwind: tailwind,
    vscode: vscode,
    npm: npm,
    git: git,
    github: github,
    figma: figma,
};

// Enter your Project Details here
export const projectDetails = [
  {
    title: "FastSaas-Boilerplate",
    image: projectImage1,
    description: `A solution to accelerate Backend Development for SaaS initiatives.`,
    techstack: "Django",
    githubLink: "https://github.com/MartinPHLP/FastSaas-Boilerplate",
  },
  {
    title: "Cmantique",
    image: projectImage2,
    description: `A sentence embeddings model for french natural language based on BERT model.`,
    techstack: "Python, Transformers, Tensorflow",
    githubLink: "https://github.com/MartinPHLP/cmantique",
  },
  {
    title: "Jumpy",
    image: projectImage3,
    description: `An automatic comment analyzer for instagram posts.`,
    techstack: "Python",
    githubLink: "https://github.com/MartinPHLP/jumpy",
  },
];

export const contactDetails = {
  email: "martin-philip@outlook.com",
  phone: "06 30 43 75 09",
};
